<template>
  <div>
    <MyHeader :title="$t('lang.menu_patrolmap')" :ShowBack="true" :BackUrl="backUrl" RightIcon="search"
      @rightclick="onRightClick" />
    <div id="container"></div>
  </div>
</template>

<style scoped>
#container {
  width: 100%;
  height: calc(100vh - 96px);
}
</style>

<script>
import MyHeader from "@/components/Header.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { GetMapGPSRecords } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

export default {
  components: { MyHeader },
  data() {
    return {
      mapObj: null,
      //aMap: null,
      icon0: null,
      iconGps: null,
      textStyle: null,

      backUrl:"",
      //labelsLayer: null,
    };
  },

  mounted() { 
    if (!this.$store.getters.getIsXunjianAPP)
      this.backUrl = "/query/index";
    else
      this.backUrl = "/query/appindex";
  },

  activated() {
    let $self = this;

    // 设置一个图标对象
    this.icon0 = {
      type: "image",
      image: "/img/poi-marker-0.png",
      size: [18, 27],
      anchor: "center",
    };
    this.iconGps = {
      type: "image",
      image: "/img/gps-marker.png",
      size: [6, 6],
      anchor: "center",
    };
    this.textStyle = {
      fontSize: 12,
      fontWeight: "normal",
      fillColor: "#22886f",
      strokeColor: "#fff",
      strokeWidth: 2,
      fold: true,
      padding: "2, 5",
    };

    AMapLoader.load({
      key: "a69152cfe2fdabeb495c2e17e3e930b2",
      version: "1.4.15",
      plugins: ["AMap.MapType"],
    })
      .then((AMap) => {
        //$self.aMap = AMap;
        $self.mapObj = new AMap.Map("container", {
          resizeEnable: true,
          zoom: 17,
          center: [116.397428, 39.90923],
        });

        $self.mapObj.addControl(
          new AMap.MapType({
            defaultType: 0, //0代表默认，1代表卫星
          })
        );

        AMap.plugin("AMap.ToolBar", function () {
          let toolbar = new AMap.ToolBar();
          $self.mapObj.addControl(toolbar);
        });

        $self.mapObj.setFitView();

        if (this.$route.params.p) {
          this.Query();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },

  methods: {
    onRightClick() {
      this.$router.push({
        name: "QueryForm",
        params: { title: this.$t("lang.menu_patrolmap"), f: "Map" },
      });
    },

    Query() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) {
        return;
      }

      let $self = this;
      let linecolor = [
        "#28F",
        "#FF9966",
        "#FFFF66",
        "#99CC66",
        "#CC9966",
        "#663366",
        "#CC3333",
        "#28F",
        "#28F",
        "#28F",
        "#28F",
      ];

      GetMapGPSRecords(
        {
          token: this.$store.getters.getToken,
          startTime: p.mDate1,
          endTime: p.mDate2,
          strCompanyIds: p.mCompany,
          strDepartmentIds: p.mDept,
          strRouteIds: p.mLine,
          strPlaceIds: p.mAddr,
        },
        (ret) => {
          if (ret && ret.code == 0) {
            for (let r in ret.data) {
              //线路循环
              let LabelsData = [];
              let lineArr = [];
              let markers = [];

              for (let i in ret.data[r]) {
                //地点循环
                let gps = ret.data[r][i];
                //过滤无效的数据
                if (
                  gps.lng == null ||
                  gps.lng < 1 ||
                  gps.lng > 180 ||
                  gps.lat == null ||
                  gps.lat < 1 ||
                  gps.lat > 90
                ) {
                  continue;
                }

                if (gps.placeName == "") {
                  var lable = {
                    name: gps.id + "|" + gps.arriveTime,
                    position: [gps.lng, gps.lat],
                    zooms: [10, 20],
                    opacity: 1,
                    zIndex: 16,
                    icon: this.iconGps,
                  };
                } else {
                  var lable = {
                    name: gps.id + "|" + gps.arriveTime,
                    position: [gps.lng, gps.lat],
                    zooms: [10, 20],
                    opacity: 1,
                    zIndex: 16,
                    icon: this.icon0,

                    text: {
                      content: gps.placeName,
                      direction: "right",
                      offset: [-5, -5],
                      style: this.textStyle,
                    },
                  };
                }
                lineArr.push(lable.position);
                LabelsData.push(lable);
              }

              // 绘制轨迹
              new AMap.Polyline({
                map: this.mapObj,
                path: lineArr,
                showDir: true,
                strokeColor: linecolor[r], //线颜色
                strokeWeight: 6, //线宽
              });

              // 初始化 labelMarker
              for (var i = 0; i < LabelsData.length; i++) {
                var curData = LabelsData[i];
                curData.extData = {
                  id: curData.name.split("|")[0],
                  arriveTime: curData.name.split("|")[1], //地点的id放入extdata
                };
                var labelMarker = new AMap.LabelMarker(curData);

                // 给marker绑定事件
                labelMarker.on("click", function (e) {
                  $self.$Toast(e.target.getExtData().arriveTime);
                });

                markers.push(labelMarker);
              }

              // 将 marker 添加到图层
              let labelsLayer = new AMap.LabelsLayer({
                zooms: [5, 20],
                zIndex: 1000,
                collision: false,
              });
              labelsLayer.add(markers);
              this.mapObj.add(labelsLayer);
            }
            this.mapObj.setFitView();
          } else {
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },

    onLoad() {
      this.Query();
    },
  },
};
</script>

